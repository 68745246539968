<template>
  <div>
    <b-card class="boxplot-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <DatasourceSelect v-model="datasourceSelected" mode="multiple" :autoLocationSelected="autoLocationSelected" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group class="mb-0">
            <option-chart :label="$t('option_chart')" :showScale="true" :shape="false" :optionsSummary="isStaticData ? undefined : true" :options="[]" :typeList="[]" :nameComponent="$options.name" />
          </b-form-group>
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData" />
          <downloader :downloadImage="downloadImage" :downloadHtml="downloadHtml" :downloadCsv="downloadCsv" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showOverlay" variant="transparent" opacity="0.85" blur="2px" rounded="sm" no-fade>
      <boxplot-chart ref="chart" :chart="chart" :showYear="showYear" :nameComponent="$options.name" />
    </b-overlay>

    <Timeplayer ref="timeplayer" :liveMode="liveMode" v-model="date" :moveToEnd="moveToEnd" />
    <AutoGroups :groupData="autoGroupData" :autoGroup="autoGroup" />
  </div>
</template>

<script>
const _ = require('lodash')
import PausePlayerMixin from '@/mixins/PausePlayerMixin'
import SelectDatasourceMixin from '@/mixins/SelectDatasourceMixin'
import ScaleMixin from '@/mixins/ScaleMixin'
import ChartYearMixin from '@/mixins/ChartYearMixin'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import Timeplayer from '../../common/Timeplayer.vue'
import DatasourceSelect from '../../common/DatasourceSelect/DatasourceSelect.vue'
import Downloader from '../../common/Downloader.vue'
import SummaryData from '../../common/SummaryData.vue'
import CheckboxSingle from '../../common/CheckboxSingle.vue'
import RadioGroup from '../../common/RadioGroup.vue'
import OptionChart from '../../common/OptionChartPlotly.vue'
import BoxplotChart from './BoxplotChartComponent.vue'
import Option from './Option.vue'
import AutoGroups from '../../common/AutoGroups.vue'
import { splitByLastIndex } from '@/utilities/StringUtility.js'
import { SelectedGroupMixin } from '@/mixins/GroupItemsMixin'
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors'
import { scaleLog10, scaleLog10Plus, scaleNormalize, scaleStandardize } from '@/utilities/NumberUtility.js'
const Quartilemethod = ['linear', 'exclusive', 'inclusive']
const statisticsDataKeys = ['Q1', 'Median', 'Q3', 'Lower', 'Upper', 'Outliers']
export default {
  name: 'boxplot',
  components: { BoxplotChart, Timeplayer, DatasourceSelect, Downloader, SummaryData, CheckboxSingle, RadioGroup, AutoGroups, Option, OptionChart },
  mixins: [SelectDatasourceMixin, PausePlayerMixin, SelectedGroupMixin, ScaleMixin, ChartYearMixin, ToastificationContent],
  data() {
    return {
      chart: { data: [], layout: {} },
      chartData: [],
      moveToEnd: 0,
      showOverlay: false,
      renderDate: [],
      autoLocationSelected: [],
      unselectLocation: [],
      isStaticData: false,
      clearShowOverlay: null,
    }
  },
  created() {
    let autoGroup = _.cloneDeep(this.autoGroup)
    if (autoGroup) {
      this.autoLocationSelected = {
        select: this.getDataLocationItemByGroup(this.autoGroupData[autoGroup]),
      }
    }
  },
  mounted() {
    let self = this
    let myPlotly = document.querySelector('.boxplot-chart')
    myPlotly.on('plotly_legendclick', function (plotly) {
      if (!self.isStaticData) {
        let dataFilter = []
        let datas = plotly.data
        let visibleFilter = true
        let countFilter = 0
        datas.map((data, id) => {
          // visible=true -> legend show , legendonly-> lengend hidden
          if (id != plotly.expandedIndex) {
            if (data.visible === true && !data.outData) {
              if (self.rotateChart == true) {
                dataFilter.push(...data.x)
              } else {
                dataFilter.push(...data.y)
              }

              countFilter++
            }
          } else {
            if (data.visible !== true && !data.outData) {
              if (self.rotateChart == true) {
                dataFilter.push(...data.x)
              } else {
                dataFilter.push(...data.y)
              }
              countFilter++
            }
          }
        })
        if (dataFilter.length == 0) {
          dataFilter = [0]
          visibleFilter = 'legendonly'
        } else {
          visibleFilter = true
        }
        if (self.rotateChart == true) {
          plotly.data[1].x = dataFilter
        } else {
          plotly.data[1].y = dataFilter
        }
        plotly.data[1].visible = countFilter < 2 ? visibleFilter : plotly.data[1].visible
      }
    })
  },
  computed: {
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date })
      },
    },
    showYear() {
      return this.$store.state.tabs[this.$options.name].year
    },
    showLegend() {
      return this.$store.state.tabs[this.$options.name].legend
    },
    markerColor() {
      let colors = this.$store.state.tabs[this.$options.name].colors
      colors = colors && colors.length > 0 ? colors : OPTION_PLOTLY_PALETTE.D3
      return colors
    },
    rotateChart() {
      return this.$store.state.tabs[this.$options.name].rotate
    },
    summary() {
      return this.$store.state.tabs[this.$options.name].summary
    },
    underlying() {
      return this.$store.state.tabs[this.$options.name].underlying
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    autoGroup() {
      return this.$store.state.tabs.boxplot.autoGroup
    },
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries')
    },
    autoGroupData() {
      let result = {}
      for (let i = 0; i < this.datasources.length; i++) {
        const metadata = this.$db[this.datasources[i].id]
        const autoGroupKeys = Object.keys(metadata.autoGroups)
        autoGroupKeys.map((key) => {
          if (!result[key]) result[key] = metadata.autoGroups[key].map((value) => value.toString().concat(`|${this.datasources[i].id}`))
          else result[key] = result[key].concat(metadata.autoGroups[key].map((value) => value.toString().concat(`|${this.datasources[i].id}`)))
        })
      }
      return result
    },
    liveMode() {
      try {
        let check = false
        if (!this.datasources || !this.datasourceSelected) return false
        let arryIdDatasources = []
        this.datasources.map((data) => {
          arryIdDatasources.push(data.id)
        })

        arryIdDatasources = _.unionBy(arryIdDatasources)
        this.datasourceSelected.map((data) => {
          let indexData = arryIdDatasources.indexOf(data.datasource)
          if (indexData > -1 && this.datasources[indexData].live) {
            check = true
            return check
          }
        })
        return check
      } catch {}
      return false
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
  },
  watch: {
    underlying() {
      this.setChart()
    },
    summary() {
      this.setChart()
    },
    rotateChart() {
      this.setChart()
    },
    markerColor() {
      this.setChart()
    },
    showLegend() {
      this.setChart()
    },
    selectedGroup: {
      deep: true,
      handler() {
        this.setChart()
      },
    },
    datasourceSelected() {
      this.moveToEnd++
      this.setChart()
    },
    scale() {
      this.setChart()
    },
    date() {
      this.setChart(true)
    },
    autoGroup: {
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal && !oldVal) return
        if (oldVal && newVal === oldVal) {
          this.autoLocationSelected = {
            select: this.getDataLocationItemByGroup(this.autoGroupData[newVal]),
          }
        } else {
          this.autoLocationSelected = {
            unSelect: this.getDataLocationItemByGroup(this.autoGroupData[oldVal]),
            select: this.getDataLocationItemByGroup(this.autoGroupData[newVal]),
          }
        }
      },
    },
    countLiveMode() {
      this.setChart()
    },
  },
  methods: {
    async setChart() {
      try {
        let traces = []
        let traceCount = this.summary == true ? 2 : 0
        let chartData = []
        let dataFilter = []
        let dataAll = []
        let checkStatic = false
        let checkNormal = false
        let layout = { showlegend: this.showLegend }
        let boxpoints = this.underlying ? 'all' : null

        // min date and max date (range case and single case)
        const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : ''
        const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : ''
        //SAVE CURRENT DATE RENDERING
        this.renderDate = [minDate, maxDate]

        // Group by datasource
        const timeseriesDatasource = this.datasourceSelected.filter((ds) => ds.location)
        if (timeseriesDatasource.length !== 0) {
          const datasourceGrouped = _.groupBy(timeseriesDatasource, (d) => d.datasource)
          for (const datasourceId in datasourceGrouped) {
            if (datasourceGrouped[datasourceId][0].location) {
              const isStatisticData = this.checkStatisticData(datasourceId)
              let locationsItems = []
              let statisticsLocation = []
              if (isStatisticData) {
                statisticsLocation = _.map(datasourceGrouped[datasourceId], (data) => {
                  return data.location
                })
                locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
                  return [`${data.location}-${statisticsDataKeys[0]}`, `${data.location}-${statisticsDataKeys[1]}`, `${data.location}-${statisticsDataKeys[2]}`, `${data.location}-${statisticsDataKeys[3]}`, `${data.location}-${statisticsDataKeys[4]}`, `${data.location}-${statisticsDataKeys[5]}`]
                })
                locationsItems = locationsItems.flat()
              } else {
                locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
                  return data.location + '-' + data.item
                })
              }
              const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale)
              if (!records.dates) continue
              // Build traces and 'chartData'
              if (isStatisticData) {
                checkStatic = true
                statisticsLocation.forEach((location) => {
                  const name = `Statistic data - ${location}`
                  const Q1 = records[`${location}-Q1`]
                  const MEDIAN = records[`${location}-Median`]
                  const Q3 = records[`${location}-Q3`]
                  const LOWERFENCE = records[`${location}-Lower`]
                  const UPPERFENCE = records[`${location}-Upper`]
                  const OUTLIERS = records[`${location}-Outliers`]
                  let axis = []
                  Q1.map((q) => axis.push([null]))
                  if (!Q1 || !MEDIAN || !Q3) {
                    this.$toast({ component: ToastificationContent, props: { title: 'Update chart failed', text: `Q1 | MEDIAN | Q3 of location ${location} is undefined`, icon: 'ArrowDownIcon', variant: 'danger' } })
                    return
                  }
                  if (this.rotateChart == true) {
                    traces.push({ xaxis: 'x4', yaxis: 'y4', x: axis, name, type: 'box', q1: Q1, median: MEDIAN, q3: Q3, lowerfence: LOWERFENCE, upperfence: UPPERFENCE, outliers: OUTLIERS, quartilemethod: Quartilemethod[2], marker: { color: this.markerColor[traceCount % this.markerColor.length] }, boxpoints })
                  } else {
                    traces.push({ xaxis: 'x4', yaxis: 'y4', y: axis, name, type: 'box', q1: Q1, median: MEDIAN, q3: Q3, lowerfence: LOWERFENCE, upperfence: UPPERFENCE, outliers: OUTLIERS, quartilemethod: Quartilemethod[2], marker: { color: this.markerColor[traceCount % this.markerColor.length] }, boxpoints })
                  }
                  traceCount++
                })
              } else {
                checkNormal = true
                for (const locationItem of locationsItems) {
                  const [location, item] = splitByLastIndex(locationItem, '-')
                  const name = locationItem.replace('-', ' - ')
                  const visible = this.selectedGroup && !this.selectedGroup.items.includes(location) ? 'legendonly' : true
                  const arrObjects = records[locationItem]
                  const x = arrObjects ? arrObjects : []
                  if (this.rotateChart == true) {
                    traces.push({ name, visible, x, type: 'box', quartilemethod: Quartilemethod[2], marker: { color: this.markerColor[traceCount % this.markerColor.length] }, boxpoints })
                  } else {
                    traces.push({ name, visible, y: x, type: 'box', quartilemethod: Quartilemethod[2], marker: { color: this.markerColor[traceCount % this.markerColor.length] }, boxpoints })
                  }
                  if (visible == true) {
                    dataFilter.push(...x)
                  }
                  dataAll.push(...x)
                  traceCount++
                  chartData = chartData.concat(x)
                }
              }
            }
          }
        }
        this.isStaticData = checkStatic
        //  check data and add allbox( box summary data)
        if (traces.length > 0 && this.summary == true && !checkStatic) {
          if (this.rotateChart == true) {
            traces.unshift({ name: this.$i18n.t('filtered'), visible: true, x: dataFilter, type: 'box', quartilemethod: Quartilemethod[2], marker: { color: this.markerColor[1] }, outData: true, boxpoints })
            traces.unshift({ name: this.$i18n.t('all_values'), visible: true, x: dataAll, type: 'box', quartilemethod: Quartilemethod[2], marker: { color: this.markerColor[0] }, outData: true, boxpoints })
          } else {
            traces.unshift({ name: this.$i18n.t('filtered'), visible: true, y: dataFilter, type: 'box', quartilemethod: Quartilemethod[2], marker: { color: this.markerColor[1] }, outData: true, boxpoints })
            traces.unshift({ name: this.$i18n.t('all_values'), visible: true, y: dataAll, type: 'box', quartilemethod: Quartilemethod[2], marker: { color: this.markerColor[0] }, outData: true, boxpoints })
          }
        }
        if (checkStatic && checkNormal) {
          if (this.rotateChart == true) {
            layout = _.merge(layout, { xaxis: { domain: [0, 1] }, xaxis4: { domain: [0, 1] }, yaxis: { domain: [0, 0.5] }, yaxis4: { domain: [0.5, 1], side: 'left' } })
          } else {
            layout = _.merge(layout, { xaxis: { domain: [0, 0.5] }, xaxis4: { domain: [0.5, 1], side: 'bottom' }, yaxis: { domain: [0, 1] }, yaxis4: { domain: [0, 1] } })
          }
        } else {
          layout = _.merge(layout, { xaxis: { domain: [0, 1] }, xaxis4: { domain: [0, 1], side: 'bottom' }, yaxis: { domain: [0, 1] }, yaxis4: { domain: [0, 1], side: 'left' } })
        }

        if (this.rotateChart == true) {
          layout = _.merge(layout, { xaxis: { showticklabels: true, type: 'linear' }, yaxis: { showticklabels: false, type: 'category' }, xaxis4: { showticklabels: true, type: 'linear' }, yaxis4: { showticklabels: false, type: 'category' } })
        } else {
          layout = _.merge(layout, { yaxis: { showticklabels: true, type: 'linear' }, xaxis: { showticklabels: false, type: 'category' }, yaxis4: { showticklabels: true, type: 'linear' }, xaxis4: { showticklabels: false, type: 'category' } })
        }
        this.chart = { data: traces, layout }
        this.chartData = chartData
      } catch {}
    },
    async getDataCSV() {
      let dataCSV = ''
      let id = 'ID'
      let item = 'ITEM'
      let unit = 'UNIT'
      let dataLines = []
      let lineCSV = ''
      let recordsDate = []
      let checkStatisticData = false

      // min date and max date (range case and single case)
      const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : ''
      const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : ''

      // Group by datasource
      let dataSort = {}
      const datasourceGrouped = _.groupBy(this.datasourceSelected, (d) => d.datasource)
      for (const datasourceId in datasourceGrouped) {
        const dataItems = _.cloneDeep(datasourceGrouped[datasourceId])
        let dataItemsSort
        try {
          dataItemsSort = _.sortBy(dataItems, ['item', 'location'])
        } catch {
          dataItemsSort = dataItems
        }
        dataSort[datasourceId] = dataItemsSort
        const isStatisticData = this.checkStatisticData(datasourceId)
        let locationsItems = []
        if (isStatisticData) {
          checkStatisticData = true
          statisticsLocation = _.map(dataItemsSort, (data) => {
            return data.location
          })
          locationsItems = _.map(dataItemsSort, (data) => {
            return [`${data.location}-${statisticsDataKeys[0]}`, `${data.location}-${statisticsDataKeys[1]}`, `${data.location}-${statisticsDataKeys[2]}`, `${data.location}-${statisticsDataKeys[3]}`, `${data.location}-${statisticsDataKeys[4]}`, `${data.location}-${statisticsDataKeys[5]}`]
          })
          locationsItems = locationsItems.flat()
        } else {
          checkStatisticData = false
          locationsItems = _.map(dataItemsSort, (data) => {
            return data.location + '-' + data.item
          })
        }
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale)
        if (records.dates && records.dates != null) {
          recordsDate = recordsDate[0] && new Date(recordsDate[0]) > new Date(records.dates[0]) ? [...new Set([...records.dates, ...recordsDate])] : [...new Set([...recordsDate, ...records.dates])]
        }
      }
      for (const datasourceId in dataSort) {
        const isStatisticData = this.checkStatisticData(datasourceId)
        let locationsItems = []
        let statisticsLocation = []
        if (isStatisticData) {
          statisticsLocation = _.map(dataSort[datasourceId], (data) => {
            return data.location
          })
          locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
            id += ',' + data.location + ',' + data.location + ',' + data.location + ',' + data.location + ',' + data.location + ',' + data.location
            item += ',' + statisticsDataKeys[0] + ',' + statisticsDataKeys[1] + ',' + statisticsDataKeys[2] + ',' + statisticsDataKeys[3] + ',' + statisticsDataKeys[4] + ',' + statisticsDataKeys[5]
            unit += ',mm,mm,mm,mm,mm,mm'
            return [`${data.location}-${statisticsDataKeys[0]}`, `${data.location}-${statisticsDataKeys[1]}`, `${data.location}-${statisticsDataKeys[2]}`, `${data.location}-${statisticsDataKeys[3]}`, `${data.location}-${statisticsDataKeys[4]}`, `${data.location}-${statisticsDataKeys[5]}`]
          })
          locationsItems = locationsItems.flat()
        } else {
          locationsItems = _.map(dataSort[datasourceId], (data) => {
            let unitTemp = ','
            try {
              unitTemp = `,${this.$db[data.datasource].items[data.item].unit}`
            } catch {}
            id += ',' + data.location
            item += ',' + data.item
            unit += unitTemp
            return data.location + '-' + data.item
          })
        }
        const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, this.scale)
        if (!records.dates) continue
        // Build traces and 'chartData'
        if (isStatisticData) {
          recordsDate.forEach((date, index) => {
            dataLines[index] = dataLines[index] && dataLines[index] != '' ? dataLines[index] : date
            statisticsLocation.forEach((location) => {
              let id = records.dates.indexOf(date)
              dataLines[index] += ',' + records[`${location}-${statisticsDataKeys[0]}`][id] + ',' + records[`${location}-${statisticsDataKeys[1]}`][id] + ',' + records[`${location}-${statisticsDataKeys[2]}`][id] + ',' + records[`${location}-${statisticsDataKeys[3]}`][id] + ',' + records[`${location}-${statisticsDataKeys[4]}`][id]
              let dataOutliers = records[`${location}-${statisticsDataKeys[5]}`][id]
              if (dataOutliers && dataOutliers.length > 0) {
                for (let i = 0; i < dataOutliers.length; i++) {
                  if (i == 0) {
                    dataLines[index] += ',' + dataOutliers[i]
                  } else {
                    dataLines[index] += '|' + dataOutliers[i]
                  }
                }
              }
            })
          })
        } else {
          recordsDate.forEach((date, index) => {
            dataLines[index] = dataLines[index] && dataLines[index] != '' ? dataLines[index] : date
            for (let m = 0; m < locationsItems.length; m++) {
              let id = records.dates.indexOf(date)
              if (id != -1) {
                dataLines[index] += ',' + records[locationsItems[m]][id]
              } else {
                dataLines[index] += ','
              }
            }
          })
        }
      }
      dataLines.forEach((line) => {
        lineCSV += line + '\r\n'
      })
      if (lineCSV != '') {
        dataCSV += id + '\r\n' + item + '\r\n' + unit + '\r\n' + lineCSV
      }
      return { dataCSV, checkStatisticData }
    },
    downloadHtml() {
      this.$refs.chart.asHtml(this.tempProject.name + '_Boxplot')
    },
    downloadImage() {
      this.$refs.chart.asImage(this.tempProject.name + '_Boxplot')
    },
    async downloadCsv() {
      let data = await this.getDataCSV()
      if (data.checkStatisticData == true) {
        this.$refs.chart.asCSV('statisticsData', data.dataCSV)
      } else {
        this.$refs.chart.asCSV(this.tempProject.name + '_Box', data.dataCSV)
      }
    },
    getDataLocationItemByGroup(groupData) {
      if (!groupData) return []
      let result = []
      for (let i = 0; i < groupData.length; i++) {
        const [locationItems, datasourceId] = splitByLastIndex(groupData[i], '|')
        const [location, item] = splitByLastIndex(locationItems, '*')
        result.push({
          datasource: datasourceId,
          item,
          location,
        })
      }
      return result
    },
  },
}
</script>
<style lang="scss" scoped>
.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-icon {
  width: 50px;
  height: 50px;
}

.loading-icon {
  -webkit-animation: loading 1s infinite;
  -moz-animation: loading 1s infinite;
  -ms-animation: loading 1s infinite;
  animation: loading 1s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear;
}

@keyframes loading {
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-flat-custom {
  background: #397bff;
  color: #fff;
}

.btn-flat-custom:hover {
  opacity: 0.9;
}
</style>
